export type Classes =
	| string
	| [string, boolean | undefined]
	| { [className: string]: boolean | undefined }
	| undefined
export default function classes(...args: Classes[]): string {
	const classes = []
	for (const arg of args) {
		if (typeof arg == 'string') {
			classes.push(arg)
		} else if (Array.isArray(arg)) {
			if (arg[1]) classes.push(arg[0])
		} else if (typeof arg == 'object') {
			for (const [k, v] of Object.entries(arg)) {
				if (v) classes.push(k)
			}
		}
	}
	return classes.join(' ')
}

import type * as appModuleType from './App'

import { enableMapSet } from 'immer'
import React, { StrictMode } from 'react'
import ReactDOM, { createRoot } from 'react-dom/client'
import { Router } from 'wouter'

import Spinner from './components/Spinner'
import { WsContextProvider } from './contexts/WsContext'
import { setAuth } from './data/auth'
import { loadProject, updateGen } from './data/helpers'
import { getPreference, onPreference } from './data/prefs'
import { clearProject } from './data/project'
import { Provider, store } from '@/data/store'
import {
	reactHistory,
	useCancellableLocation,
} from './hooks/useCancellableLocation'
import { apiCall } from './util/apiCall'
import { sendBroadcast } from './util/broadcast'

import './index.scss'

// enable Map and Set support in immer
enableMapSet()

// expose vendor dependencies
Object.assign(window, { React, ReactDOM })

// expose debug
Object.assign(window, { debug: process.env.DEBUG === '1' })

// expose store, state and history
Object.assign(window, { store })
Object.defineProperty(window, 'state', { get: () => store.getState() })
Object.assign(window, { reactHistory })

// setup theme based on preferences
{
	const updateTheme = () => {
		const darkTheme = getPreference('themeDark')
		document.body.dataset.theme = darkTheme ? 'dark' : 'light'
	}
	updateTheme()
	onPreference('themeDark', updateTheme)
}

// create app root
const root = createRoot(document.querySelector('#app-root')!)

// render a spinner while the app is loading
root.render(
	<main
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}>
		<Spinner />
	</main>,
)

// initialize history
Object.assign(reactHistory, [location.pathname])

// load the app
let appModule: typeof appModuleType | null = null
await Promise.all([
	// check login and project
	(async () => {
		try {
			const { email, displayName, uuid, admin, expires, project } =
				await apiCall('/auth/check', { type: true })
			store.dispatch(
				setAuth({
					email,
					displayName,
					uuid,
					admin,
					expires,
					project,
				}),
			)
			sendBroadcast('login', {
				email,
				displayName,
				uuid,
				admin,
				expires,
				project,
			})

			if (!project) {
				store.dispatch(clearProject())
				return
			}

			await Promise.all([
				loadProject(store.dispatch),
				updateGen(store.dispatch),
			])
		} catch (e) {
			if (debug) console.error(e)
		}
	})(),

	// load app code
	(async () => {
		appModule = await import('./App')
	})(),
])

// render app
const App = appModule!.default
root.render(
	<StrictMode>
		<Provider>
			<WsContextProvider>
				<Router hook={useCancellableLocation}>
					<App />
				</Router>
			</WsContextProvider>
		</Provider>
	</StrictMode>,
)

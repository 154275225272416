import type { PayloadAction } from '@reduxjs/toolkit'

import type { Generation } from '@horfix/horfix-common/types/data/gen'

import { createSlice } from '@reduxjs/toolkit'

export type GenerationState = Omit<Generation, 'data'> & {
	user_info?: GenerationUser
}
export type GenerationUser = {
	display_name: string
	email: string
}

export type GenState = {
	current: GenerationState | null
}
const initialAuthState: GenState = {
	current: null,
}

const genSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		setCurrent: (
			_state,
			{ payload }: PayloadAction<GenerationState | null>,
		) => {
			return { current: payload }
		},
		setStep: (
			state,
			{ payload }: PayloadAction<number | { step: number; date: string }>,
		) => {
			if (state.current) {
				state.current.step =
					typeof payload === 'number' ? payload : payload.step
				if (typeof payload === 'object')
					state.current.last_date = payload.date
			}
		},
		setFinished: (
			state,
			{
				payload,
			}: PayloadAction<boolean | { finished: boolean; date: string }>,
		) => {
			if (state.current) {
				state.current.finished =
					typeof payload === 'boolean' ? payload : payload.finished
				if (typeof payload === 'object')
					state.current.last_date = payload.date
			}
		},
		setDate: (state, { payload }: PayloadAction<string>) => {
			if (state.current) {
				state.current.last_date = payload
			}
		},
	},
})

export const { setCurrent, setStep, setFinished, setDate } = genSlice.actions
export default genSlice.reducer

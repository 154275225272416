import type { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from '@reduxjs/toolkit'

export type AuthState = {
	email: string | null
	displayName: string | null
	uuid: string | null
	expires: string | null
	admin: boolean | null
	project: string | null
}
const initialAuthState: AuthState = {
	email: null,
	displayName: null,
	uuid: null,
	expires: null,
	admin: null,
	project: null,
}

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		logoutAuth: state => {
			state.email = null
			state.displayName = null
			state.uuid = null
			state.expires = null
			state.admin = null
			state.project = null
		},
		setAuth: (_state, { payload }: PayloadAction<AuthState>) => {
			return payload
		},
		setAuthDisplayName: (state, { payload }: PayloadAction<string>) => {
			state.displayName = payload
		},
		setAuthEmail: (state, { payload }: PayloadAction<string>) => {
			state.email = payload
		},
		setAuthAdmin: (state, { payload }: PayloadAction<boolean>) => {
			state.admin = payload
		},
		setAuthProject: (state, { payload }: PayloadAction<string | null>) => {
			state.project = payload
		},
		setAuthExpires: (state, { payload }: PayloadAction<string>) => {
			return { ...state, expires: payload }
		},
	},
})

export const {
	logoutAuth,
	setAuth,
	setAuthExpires,
	setAuthDisplayName,
	setAuthEmail,
	setAuthAdmin,
	setAuthProject,
} = authSlice.actions
export default authSlice.reducer

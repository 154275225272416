import type { AuthState } from '@/data/auth'

import type { JSONData } from '@horfix/horfix-common/types/json'

import { MapSet } from '@horfix/horfix-common/util/mapSet'

const channel = new BroadcastChannel('horfix')

export interface SharedData {
	generatingInstance: string | null
	instances: string[]
}

export interface BroadcastMessage {
	logout: null
	login: AuthState

	join: null
	shared: SharedData
	ping: string
}

const msgHandlers = new MapSet<string, (data: JSONData) => void>()

channel.addEventListener('message', e => {
	const { type, data } = JSON.parse(e.data as string)
	const handlers = msgHandlers.get(type)
	if (!handlers) {
		console.warn('Unhandled broadcast message', { type, data })
		return
	}
	for (const handler of handlers) {
		try {
			handler(data)
		} catch (e) {
			console.error(e)
		}
	}
})

export function sendBroadcast<K extends keyof BroadcastMessage>(
	type: K,
	data: BroadcastMessage[K],
) {
	channel.postMessage(JSON.stringify({ type, data }))
}

export function onBroadcast<K extends keyof BroadcastMessage>(
	type: K,
	handler: (data: BroadcastMessage[K]) => void,
): void {
	msgHandlers.addSet(type, handler as any)
}

export function offBroadcast<K extends keyof BroadcastMessage>(
	type: K,
	handler: (data: BroadcastMessage[K]) => void,
): void {
	msgHandlers.delSet(type, handler as any)
}

let sharedData: SharedData = initSharedData()
const sharedDataHandlers = new Set<(data: SharedData) => void>()

onBroadcast('join', () => sendBroadcast('shared', sharedData))
sendBroadcast('join', null)

function pingSharedData(data: SharedData) {
	for (const handler of sharedDataHandlers) {
		try {
			handler(data)
		} catch (e) {
			console.error(e)
		}
	}
}

function initSharedData(): SharedData {
	return {
		generatingInstance: null,
		instances: [],
	}
}

export function getSharedData() {
	return sharedData
}

export function setSharedData(data: SharedData) {
	sharedData = data
	sendBroadcast('shared', data)
	pingSharedData(data)
}

onBroadcast('shared', data => {
	sharedData = data
	pingSharedData(data)
})

export function onSharedData(handler: (data: SharedData) => void) {
	sharedDataHandlers.add(handler)
}

export function offSharedData(handler: (data: SharedData) => void) {
	sharedDataHandlers.delete(handler)
}

Object.assign(window, {
	broadcast: { on: onBroadcast, off: offBroadcast, send: sendBroadcast },
	sharedData: {
		get: getSharedData,
		set: setSharedData,
		on: onSharedData,
		off: offSharedData,
	},
})

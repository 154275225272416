import { MapSet } from './mapSet'

interface NodeStyleCallback<T, E extends NonNullable<unknown>> {
	(err: E, val: unknown): void
	(err: null | undefined, val: T): void
}

interface ClassType<T, A extends unknown[] = any[]> {
	new (...args: A): T
	readonly prototype: T
}

const instances = new Map<ClassType<unknown>, any>()
const building = new MapSet<
	ClassType<unknown>,
	NodeStyleCallback<any, NonNullable<unknown>>
>()

/**
 * Get the singleton instance of the given class
 *
 * This version builds it by constructor
 */
export function getInstance<T>(
	/**
	 * The class to get the singleton instance of
	 */
	cls: ClassType<T, []>
): T
/**
 * Get the singleton instance of the given class
 *
 * This version builds it by calling the given async function
 */
export function getInstance<T>(
	/**
	 * The class to get the singleton instance of
	 */
	cls: ClassType<T>,
	/**
	 * The builder function
	 */
	fn: () => Promise<T>
): Promise<T>
/**
 * Get the singleton instance of the given class
 *
 * This version builds it by calling the given sync function
 */
export function getInstance<T>(
	/**
	 * The class to get the singleton instance of
	 */
	cls: ClassType<T>,
	/**
	 * The builder function
	 */
	fn: () => T
): T
/**
 * Get the singleton instance of the given class
 */
export function getInstance<T>(
	/**
	 * The class to get the singleton instance of
	 */
	cls: ClassType<T>,
	/**
	 * The builder function
	 */
	fn?: () => T | Promise<T>
): T | Promise<T> {
	const instance = instances.get(cls)
	if (instance) return instance

	if (building.has(cls)) {
		return new Promise<T>((ok, ko) => {
			const buildingCls = building.get(cls)
			if (!buildingCls) return ok(getInstance(cls, fn as any))
			buildingCls.add((err, val) => {
				if (err) ko(err)
				else ok(val)
			})
		})
	}

	const newInstance = fn ? fn() : new cls()
	if (
		(typeof newInstance == 'function' || typeof newInstance == 'object') &&
		newInstance &&
		'then' in newInstance &&
		typeof newInstance.then == 'function'
	) {
		const buildingCls = building.getSet(cls)
		newInstance.then(
			instance => {
				instances.set(cls, instance)
				buildingCls.forEach(h => h(null, instance))
				building.delete(cls)
			},
			err => {
				buildingCls.forEach(h => h(err, undefined))
				building.delete(cls)
			}
		)
		return newInstance
	}
	instances.set(cls, newInstance)
	return newInstance
}

/**
 * Checks if a given class already has a singleton instance
 */
export function hasInstance(cls: ClassType<any>): boolean {
	return instances.has(cls)
}
/**
 * Checks if a given class has a singleton instance currently being built
 */
export function isBuilding(cls: ClassType<any>): boolean {
	return building.has(cls)
}

/**
 * Lists the known classes that have singleton instances
 */
export function* getClasses(): Generator<ClassType<any>> {
	for (const cls of instances.keys()) {
		yield cls
	}
}

/**
 * Builds a task that can run in the background exactly once, returning true if it wasnt already running
 */
export function background(fn: () => Promise<void>): () => boolean {
	let running = false
	return () => {
		if (running) return false
		running = true
		void fn()
		return true
	}
}

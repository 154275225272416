import { createSlice } from '@reduxjs/toolkit'

let windowNameData: { uuid: string } | null = null
try {
	windowNameData = JSON.parse(window.name)
	if (
		!windowNameData ||
		typeof windowNameData !== 'object' ||
		Array.isArray(windowNameData)
	)
		throw new Error('not object')
	if (!('uuid' in windowNameData)) throw new Error('invalid type')
} catch {
	windowNameData = null
}

if (!windowNameData) {
	windowNameData = { uuid: crypto.randomUUID() }
}

window.name = JSON.stringify(windowNameData)

export type InstanceState = {
	uuid: string
}
const initialInstanceState: InstanceState = {
	uuid: windowNameData.uuid,
}

const instanceSlice = createSlice({
	name: 'instance',
	initialState: initialInstanceState,
	reducers: {},
})

export default instanceSlice.reducer

/**
 * Creates a promise with exposed functions to resolve or reject it, as well as an exposed status
 */
export default function delayedPromise<T>(): Promise<T> & {
	/**
	 * Resolves the promise with the provided value
	 */
	resolve: (v: T) => void
	/**
	 * Rejects the promise with the provided error
	 */
	reject: (e: any) => void
	/**
	 * Current state of the promise
	 */
	status:
		| { s: 'pending' }
		| { s: 'resolved'; v: T }
		| { s: 'rejected'; e: any }
} {
	let status:
		| { s: 'pending' }
		| { s: 'resolved'; v: T }
		| { s: 'rejected'; e: any } = { s: 'pending' }
	let resolveFn: (v: T) => void
	let rejectFn: (e: any) => void
	const p = new Promise<T>((ok, ko) => {
		resolveFn = (v: T) => {
			status = { s: 'resolved', v }
			ok(v)
		}
		rejectFn = (e: any) => {
			status = { s: 'rejected', e }
			ko(e)
		}
		if (status.s === 'resolved') ok(status.v)
		if (status.s === 'rejected') ko(status.e)
	})
	return Object.defineProperty(
		Object.assign(p, {
			resolve: (v: T) => {
				if (resolveFn) resolveFn(v)
				else status = { s: 'resolved', v }
			},
			reject: (e: any) => {
				if (rejectFn) rejectFn(e)
				else status = { s: 'rejected', e }
			},
		}),
		'status',
		{ enumerable: true, get: () => status }
	) as ReturnType<typeof delayedPromise<T>>
}

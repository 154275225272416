export function matchPath(
	path: string,
	pattern: string,
): Record<string, string> | null {
	if (pattern === path) return {}

	const patternParts = pattern.slice(1).split('/')
	const pathParts = path.slice(1).split('/')
	if (patternParts.length > pathParts.length) return null
	if (
		pathParts.length > patternParts.length &&
		!patternParts.at(-1)!.startsWith('*')
	)
		return null

	const matches: Record<string, string> = {}

	for (let i = 0; i < patternParts.length; i++) {
		const patternPart = patternParts[i]
		const pathPart = pathParts[i]

		if (patternPart.startsWith('*')) {
			if (i !== patternParts.length - 1) {
				throw new Error(`Invalid pattern ${pattern}`)
			}
			const remaining = decodeURIComponent(pathParts.slice(i).join('/'))
			if (patternPart === '*') {
				matches['*'] = remaining
				continue
			}
			const mat = patternPart.match(/^\*:([^:]+)$/)
			if (!mat) throw new Error(`Invalid pattern ${pattern}`)
			const [, name] = mat
			matches[name] = remaining
			continue
		}

		if (!patternPart.includes(':')) {
			if (pathPart !== patternPart) return null
			continue
		}

		const mat = patternPart.match(/^:([^:]+)$/)
		if (!mat) throw new Error(`Invalid pattern ${pattern}`)
		const [, name] = mat
		matches[name] = decodeURIComponent(pathPart)
	}

	return matches
}

export function matchPaths(
	path: string,
	patterns: string[],
): [string, Record<string, string>] | null {
	for (const pattern of patterns) {
		if (path === pattern) return [pattern, {}]
	}
	for (const pattern of patterns) {
		const ret = matchPath(path, pattern)
		if (ret) return [pattern, ret]
	}
	return null
}

export function applyPath(
	pattern: string,
	data: Record<string, string | number> = {},
): string {
	return (
		'/' +
		pattern
			.slice(1)
			.split('/')
			.map(part => {
				if (part.startsWith('*')) {
					if (part === '*') {
						if (!('*' in data)) throw new Error(`Missing data: *`)
						return encodeURIComponent(data['*']).replace(
							/%2f/gi,
							'/',
						)
					}

					const mat = part.match(/^\*:([^:]+)$/)
					if (!mat) throw new Error(`Invalid pattern ${pattern}`)
					const [, name] = mat
					if (!(name in data))
						throw new Error(`Missing data: ${name}`)
					return encodeURIComponent(data[name]).replace(/%2f/gi, '/')
				}

				if (part.includes(':')) {
					const mat = part.match(/^:([^:]+)$/)
					if (!mat) throw new Error(`Invalid pattern ${pattern}`)
					const [, name] = mat
					if (!(name in data))
						throw new Error(`Missing data: ${name}`)
					return encodeURIComponent(data[name])
				}

				return part
			})
			.join('/')
	)
}

import { eq } from '@horfix/horfix-common/util/eq'

export type Preferences = {
	// shows the percentage progression by default on progress bars, instead of the current step and step count
	progressShowPercent: boolean

	// shows the estimated duration in approximation (in a few seconds) by default instead of the actual expected time
	progressShowApproximateEstimate: boolean

	// shows the current step in progresses
	progressShowStep: boolean

	// shows holidays and school breaks on step 8 only when they intersect with the service
	calendarOnlyIntersects: boolean

	// show paginator and element counts on the bottom of tables instead of the top
	tableExtrasBottom: boolean

	// automatically unselects elements after an edit or revert operation on a table
	tableAutoUnselect: boolean

	// use a dark theme for the app instead of the default light one
	themeDark: boolean

	// hide admin powers
	hideAdmin: boolean

	// show the project indicator at the top right of the screen
	projectIndicator: boolean
}
export type Preference = keyof Preferences

export const defaultPreferences: Preferences = Object.freeze({
	progressShowPercent: true,
	progressShowApproximateEstimate: true,
	progressShowStep: false,
	calendarOnlyIntersects: false,
	tableExtrasBottom: false,
	tableAutoUnselect: false,

	// as much as this pains me
	themeDark: false,

	hideAdmin: false,

	projectIndicator: false,
})

const preferenceValidation: { [K in keyof Preferences]: (x: any) => boolean } =
	{
		progressShowPercent: x => typeof x == 'boolean',
		progressShowApproximateEstimate: x => typeof x == 'boolean',
		progressShowStep: x => typeof x == 'boolean',
		calendarOnlyIntersects: x => typeof x == 'boolean',
		tableExtrasBottom: x => typeof x == 'boolean',
		tableAutoUnselect: x => typeof x == 'boolean',
		themeDark: x => typeof x == 'boolean',
		hideAdmin: x => typeof x == 'boolean',
		projectIndicator: x => typeof x == 'boolean',
	}

function parsePrefs(value: any): Preferences {
	try {
		if (typeof value == 'function') return parsePrefs(value())
		if (typeof value != 'string') return defaultPreferences
		const data = JSON.parse(value)
		const prefs: Partial<Preferences> = {}
		for (const [k, val] of Object.entries(preferenceValidation)) {
			if (k in data && val(data[k])) {
				prefs[k as Preference] = data[k]
			} else {
				prefs[k as Preference] = defaultPreferences[
					k as keyof Preferences
				] as any
			}
		}
		return prefs as Preferences
	} catch (e) {
		console.warn('Could not parse preferences', e)
		return defaultPreferences
	}
}

let preferences = parsePrefs(() => localStorage.getItem('horfix-prefs'))
try {
	localStorage.setItem('horfix-prefs', JSON.stringify(preferences))
} catch (e) {
	console.warn(`Preferences cannot be saved`, e)
}

const globalListeners = new Set<() => void>()
const keyListeners = new Map<Preference, Set<() => void>>(
	Object.keys(preferences).map(key => [key as Preference, new Set()]),
)

export function getPreferences(): Preferences {
	return { ...preferences }
}
export function getPreference<K extends Preference>(key: K): Preferences[K] {
	return preferences[key]
}

export function onPreferences(handler: () => void) {
	globalListeners.add(handler)
}
export function offPreferences(handler: () => void) {
	globalListeners.delete(handler)
}
export function onPreference(pref: Preference, handler: () => void) {
	keyListeners.get(pref)?.add(handler)
}
export function offPreference(pref: Preference, handler: () => void) {
	keyListeners.get(pref)?.delete(handler)
}

export function setPreference<K extends Preference>(
	key: K,
	value: Preferences[K],
) {
	if (!preferenceValidation[key](value)) return
	if (eq(value, preferences[key])) return

	preferences = { ...preferences, [key]: value }
	try {
		localStorage.setItem('horfix-prefs', JSON.stringify(preferences))
	} catch (e) {
		console.warn(`Preferences cannot be saved, key ${key} will be lost`, e)
	}

	globalListeners.forEach(x => x())
	keyListeners.get(key)?.forEach(x => x())
}

export function setPreferences(newPrefs: Preferences) {
	const oldPrefs = preferences

	for (const [k, val] of Object.entries(preferenceValidation)) {
		if (!val(newPrefs[k as Preference])) return
	}

	let changed = false
	preferences = newPrefs
	for (const [k, newValue] of Object.entries(newPrefs)) {
		const key = k as Preference
		const oldValue = oldPrefs[key]

		if (eq(newValue, oldValue)) continue
		changed = true
		keyListeners.get(key)?.forEach(x => x())
	}

	if (!changed) return
	globalListeners.forEach(x => x())
}

export function clearPreferences() {
	setPreferences(defaultPreferences)
	try {
		localStorage.removeItem('horfix-prefs')
	} catch (e) {
		console.warn(`Preferences cannot be cleared`, e)
	}
}

addEventListener('storage', e => {
	if (e.key === 'horfix-prefs' && e.storageArea === localStorage) {
		setPreferences(parsePrefs(e.newValue))
	}
})

Object.assign(window, {
	prefs: Object.defineProperty(
		{
			get: (key?: Preference) =>
				key ? getPreference(key) : getPreferences(),
			set: <K extends Preference>(key: K, value: Preferences[K]) =>
				setPreference(key, value),
		},
		'all',
		{ get: () => getPreferences() },
	),
})

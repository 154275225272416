import { useCallback, useEffect } from 'react'
import { useLocation as useCookedLocation } from 'wouter'
import useRawLocation from 'wouter/use-location'

import { pageInfos, type PageInfo } from '@/sitemap'
import { applyPath, matchPaths } from '@/util/paths'

import useFollow from './useFollow'

type LocationCanceller = (params: {
	currentLocation: string
	newLocation: string
	replace: boolean
	force: () => void
}) => void | boolean

type ReactHistory = readonly string[] & {
	readonly current: string
	readonly last: string | null
}

export const reactHistory: ReactHistory = Object.defineProperties(
	[] as readonly string[],
	{
		current: {
			get(): string {
				return this.at(-1) ?? location.pathname
			},
		},
		last: {
			get(): string | null {
				return this.at(-2) ?? null
			},
		},
	},
) as ReactHistory

export const locationCancellers: LocationCanceller[] = []

export function useCancellableLocation(): [
	location: string,
	setLocation: (
		location: string,
		params?: { replace?: boolean; force?: boolean },
	) => void,
] {
	const [location, rawSetLocation] = useRawLocation()
	const locationFollow = useFollow(location)

	const setLocation = useCallback(
		function (
			location: string,
			{
				replace = false,
				force = false,
			}: { replace?: boolean; force?: boolean } = {},
		) {
			let redirected = false

			if (debug) {
				console.log(
					`setLocation("${location}", {replace: ${replace}, force: ${force}})`,
				)
			}

			let pageInfo: PageInfo | undefined = undefined
			let params: Record<string, string> = {}

			if (reactHistory.current in pageInfos) {
				pageInfo = pageInfos[reactHistory.current]
			}

			if (!pageInfo) {
				const match = matchPaths(
					reactHistory.current,
					Object.keys(pageInfos),
				)
				if (match) {
					pageInfo = pageInfos[match[0]]
					params = match[1]
				}
			}

			if (location.startsWith('.')) {
				location = new URL(
					location,
					new URL(reactHistory.current, window.location.href),
				).pathname
			}
			if (location.match(/^[0-9]+$/) && !isNaN(+location)) {
				location = reactHistory.at(-+location - 1) ?? '/'
			}

			if (!location.startsWith('/')) {
				throw new Error(
					`Failed to setLocation("${location}"): location is not absolute`,
				)
			}

			location = applyPath(location, params)

			function actuallySetLocation() {
				redirected = true

				const writableHistory = reactHistory as unknown as string[]
				if (replace) {
					writableHistory[writableHistory.length - 1] = location
				} else {
					writableHistory.push(location)
				}
				rawSetLocation(location, { replace })
			}

			const cancellerParams = {
				currentLocation: locationFollow.current,
				newLocation: location,
				replace,
				force: () => {
					if (redirected) throw new Error('Already redirected')
					actuallySetLocation()
				},
			}

			try {
				if (!force) {
					for (const canceller of locationCancellers) {
						const val = canceller(cancellerParams)
						if (val === false) {
							throw new Error(
								`Navigation to ${location} cancelled`,
							)
						}
						// force() could have been called at any point here
						// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
						if (redirected) return
					}
				}

				actuallySetLocation()
			} catch {
				// we skipped the navigation
			}
		},
		[rawSetLocation, locationFollow],
	)

	return [location, setLocation]
}

export function useBlockLocation(canceller: LocationCanceller): void
export function useBlockLocation(
	block: boolean,
	canceller: LocationCanceller,
): void
export function useBlockLocation(
	blockOrCanceller: boolean | LocationCanceller,
	cancellerIfNoBlock?: LocationCanceller,
): void {
	const block = !!blockOrCanceller
	const canceller =
		typeof blockOrCanceller == 'function'
			? blockOrCanceller
			: cancellerIfNoBlock!
	const cancellerFollow = useFollow(canceller)

	useEffect(() => {
		if (!block) return

		const canceller: LocationCanceller = params =>
			cancellerFollow.current(params)

		locationCancellers.unshift(canceller)
		return () => {
			const idx = locationCancellers.indexOf(canceller)
			if (idx !== -1) locationCancellers.splice(idx, 1)
		}
	}, [block, cancellerFollow])
}

export const useLocation = useCookedLocation<typeof useCancellableLocation>

import { idAssert } from './id'

/**
 * Checks for deep equalty between two objects
 *
 * Primitives are checked by `===` or `Object.is`
 *
 * Arrays, Sets and Maps are checked recursively in order
 *
 * Objects are equal if they have the same constructor and all their keys are the same and have the same values
 */
export function eq<T>(a: T, b: T): boolean {
	// strict equalty
	if (a === b || Object.is(a, b)) return true
	if ((a === null) !== (b === null)) return false

	// only objects can be deep equal if not strict equal
	if (typeof a !== 'object' || typeof b !== 'object') return false

	idAssert<T & object>(a)
	idAssert<T & object>(b)

	// two arrays are deep equal if they are the same length
	// and each pair sourced from both arrays is deep equal
	if (Array.isArray(a) && Array.isArray(b))
		return a.length === b.length && a.every((e, i) => eq(e, b[i]))

	// two maps are deep equal if they have the same entries
	if (a instanceof Map && b instanceof Map) {
		return eq([...a.entries()], [...b.entries()])
	}

	// two sets are deep equal if they have the same values in order
	if (a instanceof Set && b instanceof Set) return eq([...a], [...b])

	// if two objects don't have the same constructor they can't be deep equal
	if (a.constructor === b.constructor)
		// two objects must have the same keys to be deep equal
		// and each pair sourced from both objects must also be deep equal
		return (
			eq(Object.keys(a).sort(), Object.keys(b).sort()) &&
			Object.keys(a).every(k => eq((a as any)[k], (b as any)[k]))
		)

	// not deep equal by default
	return false
}

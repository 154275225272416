import type { RootState } from '@/data/store'
import type { Children } from '@/types/react'

import type { JSONObject, JSONToplevel } from '@horfix/horfix-common/types/json'

import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import useFollow from '@/hooks/useFollow'

import { eq } from '@horfix/horfix-common/util/eq'
import { fmtDuration } from '@horfix/horfix-common/util/fmt'
import { id } from '@horfix/horfix-common/util/id'

export type WsAndUuid = { ws: WebSocket; uuid: string }
export type WsContextData = WsAndUuid & {
	cmdHandlers: Map<string, CmdH>
	idHandlers: Map<string, MshH>
}
export type WsConnectionInfo = {
	connected: boolean
	retryTimeout: number
	retryCount: number
	lastPing: [sent: number | null, got: boolean, data: string | null]
	pingStatus: 'none' | 'ok' | 'dead'
	pingDelay: number
}

export type Cmd<
	C extends string = string,
	D extends JSONObject | null = JSONObject,
> = {
	id: string
	type: 'cmd'
	cmd: C
	args: D
}
export type Reply<D extends JSONObject | null = JSONObject> = {
	id: string
	reply: string
	type: 'reply'
} & (
	| {
			ok: true
			data?: D | null
	  }
	| {
			ok: false
			err: string
			extended?: JSONToplevel
	  }
)
export type Progress<D extends JSONObject = JSONObject> = {
	id: string
	reply: string
	type: 'progress'
	data: D
}
export type Message = Cmd | Reply | Progress

export type CmdH<C extends string = string> = (
	cmd: Cmd<C>,
	ws: WebSocket,
) => unknown
export type MshH<
	R extends JSONObject | null = JSONObject,
	P extends JSONObject = JSONObject,
> = (msg: Reply<R> | Progress<P>) => unknown

const WsContext = createContext<WsContextData | null>(null)
export default WsContext

export const WsConnectionInfoContext = createContext<WsConnectionInfo | null>(
	null,
)

export const lastId = { id: 0 }
Object.assign(window, { ws: null })

export function WsContextProvider({ children }: { children: Children }) {
	const [data, setData] = useState<WsAndUuid | null>(null)
	const [connectionInfo, setConnectionInfo] = useState<WsConnectionInfo>({
		connected: false,
		retryCount: 0,
		retryTimeout: 0,
		lastPing: [null, false, null],
		pingStatus: 'none',
		pingDelay: 0,
	})
	const dataRef = useRef(data)
	dataRef.current = data

	const cmdHRef = useRef(new Map<string, CmdH>())
	const idHRef = useRef(new Map<string, MshH>())

	if (!cmdHRef.current.has('ping')) {
		cmdHRef.current.set('ping', async (msg, ws) => {
			ws.send(
				JSON.stringify(
					id<Reply<JSONObject | null>>({
						id: '' + lastId.id++,
						reply: msg.id,
						type: 'reply',
						ok: true,
						data: msg.args,
					}),
				),
			)
		})
	}

	const recRef = useRef({ reconnect: true, timer: 1000 })
	const connectingRef = useRef(false)

	const authUuid = useSelector((state: RootState) => state.auth.uuid)
	const authUuidFollow = useFollow(authUuid)

	const previousIdRef = useRef<string | null>(null)
	const sendPing = useCallback(() => {
		if (previousIdRef.current) {
			idHRef.current.delete(previousIdRef.current)
		}

		const ws = dataRef.current?.ws
		if (!ws) return

		const top = new Date().getTime()
		const data =
			'ping:' +
			Array(16)
				.fill(null)
				.map(() => Math.floor(Math.random() * 16).toString(16))
				.join('')
		const msg: {
			id: string
			type: 'cmd'
			cmd: 'ping'
			args: { data: string }
		} = { id: data, type: 'cmd', cmd: 'ping', args: { data } }

		idHRef.current.set(data, msg => {
			if (msg.type !== 'reply' || !msg.ok || !eq(msg.data, { data })) {
				console.warn('Websocket ping dead, invalid data', msg)
				setConnectionInfo(prev => ({ ...prev, pingStatus: 'dead' }))
			} else {
				const delay = new Date().getTime() - top
				if (debug)
					console.log('Websocket ping <-', data, fmtDuration(delay))
				setConnectionInfo(prev => {
					const next = { ...prev }
					next.pingDelay = delay
					next.pingStatus = 'ok'
					next.lastPing = [...next.lastPing]
					next.lastPing[1] = true
					return next
				})
			}
		})
		previousIdRef.current = data

		if (debug) console.log('Websocket ping ->', data)
		ws.send(JSON.stringify(msg))
		setConnectionInfo(prev => {
			const next = { ...prev }
			if (next.lastPing[0] && !next.lastPing[1]) {
				next.pingStatus = 'dead'
				console.warn('Websocket ping dead, two in a row')
			}
			next.lastPing = [top, false, data]
			return next
		})
	}, [])

	const connectWs = useCallback(
		function connectWs() {
			if (connectingRef.current) return
			if (dataRef.current) return
			if (!authUuidFollow.current) return
			setData(null)
			setConnectionInfo(prev => ({
				connected: false,
				retryCount: prev.retryCount + 1,
				retryTimeout: prev.retryTimeout,
				lastPing: [null, false, null],
				pingStatus: 'none',
				pingDelay: 0,
			}))
			Object.assign(window, { ws: null })

			const url = new URL('/api/ws', location.href)
			url.protocol = url.protocol.replace(/^http/, 'ws')
			const ws = new WebSocket(url)
			ws.onmessage = e => {
				connectingRef.current = false
				const { uuid } = JSON.parse(e.data) as { uuid: string }
				setData({ ws, uuid })
				setConnectionInfo({
					connected: true,
					retryCount: 0,
					retryTimeout: 0,
					lastPing: [null, false, null],
					pingStatus: 'none',
					pingDelay: 0,
				})
				Object.assign(window, { ws: { ws, uuid } })
				recRef.current.timer = 1000
				if (debug) console.log('Websocket ready', uuid)

				ws.onmessage = e => {
					const { current: cmdHandlers } = cmdHRef
					const { current: idHandlers } = idHRef

					const msg: Message = JSON.parse(e.data)
					if (msg.type === 'cmd') {
						if (cmdHandlers.has(msg.cmd)) {
							cmdHandlers.get(msg.cmd)!(msg, ws)
						} else {
							ws.send(
								JSON.stringify(
									id<Reply>({
										id: '' + lastId.id++,
										reply: msg.id,
										type: 'reply',
										ok: false,
										err: 'no_such_cmd',
									}),
								),
							)
						}
					} else {
						if (idHandlers.has(msg.reply)) {
							idHandlers.get(msg.reply)!(msg)
						}
					}
				}

				dataRef.current = { ws, uuid }
				sendPing()
			}

			ws.onerror = ws.onclose = () => {
				connectingRef.current = false
				setData(null)
				Object.assign(window, { ws: null })

				let timeout = 0
				if (recRef.current.reconnect) {
					console.warn(
						`Websocket disconnected, reconnecting in ${fmtDuration(
							recRef.current.timer,
						)}...`,
					)
					window.setTimeout(connectWs, recRef.current.timer)
					recRef.current.timer *= 2
					if (recRef.current.timer > 60 * 1000)
						recRef.current.timer = 60 * 1000
					timeout = recRef.current.timer
				}
				setConnectionInfo(prev => ({
					connected: false,
					retryCount: prev.retryCount,
					retryTimeout: timeout,
					lastPing: [null, false, null],
					pingStatus: 'none',
					pingDelay: 0,
				}))

				try {
					ws.close()
				} catch {
					// ignore
				}
			}

			connectingRef.current = true
		},
		[authUuidFollow, sendPing],
	)

	useEffect(() => {
		const handle = window.setInterval(sendPing, 1000 * 30)
		return () => {
			window.clearInterval(handle)
		}
	}, [sendPing])

	useEffect(() => {
		if (connectionInfo.pingStatus === 'dead') {
			dataRef.current?.ws.close()
		}
	}, [connectionInfo.pingStatus])

	useEffect(() => {
		if (authUuid) {
			connectWs()
		} else {
			dataRef.current?.ws.close()
			setData(null)
		}

		return () => {
			setData(null)
		}
	}, [connectWs, authUuid])

	useEffect(() => {
		Object.assign(window, { wsInfo: connectionInfo })
	}, [connectionInfo])

	const { current: cmdHandlers } = cmdHRef
	const { current: idHandlers } = idHRef

	return (
		<WsContext.Provider
			value={data ? { ...data, cmdHandlers, idHandlers } : null}>
			<WsConnectionInfoContext.Provider value={connectionInfo}>
				{children}
			</WsConnectionInfoContext.Provider>
		</WsContext.Provider>
	)
}

import { getInstance } from './singleton'

/**
 * Runner interface, used to... run async functions
 */
export interface Runner {
	/**
	 * The function that actually runs the function
	 */
	run<T>(
		/**
		 * The function to run
		 */
		fn: () => Promise<T>
	): Promise<Awaited<T>>
}

/**
 * Parallel runner, that runs all functions given in parallel
 */
export class ParallelRunner implements Runner {
	async run<T>(fn: () => Promise<T>): Promise<Awaited<T>> {
		return await fn()
	}
}
/**
 * Instance of {@link ParallelRunner}, since there is no real reason to make multiple instances
 */
export const parallelRunner = getInstance(ParallelRunner)

/**
 * Sequential runner, that runs all functions given one after the other
 */
export class SequentialRunner implements Runner {
	#promise: null | Promise<any> = null

	/**
	 * If the runner is currently running a function
	 */
	get running(): boolean {
		return !!this.#promise
	}

	run<T>(fn: () => Promise<T>): Promise<Awaited<T>> {
		const promise = this.#promise
			? this.#promise.then(() => this.run(fn))
			: fn()

		this.#promise = promise
			.catch(() => null)
			.finally(() => void (this.#promise = null))
		return promise.then(async x => await x) as Promise<Awaited<T>>
	}
}

/**
 * Single instance runner, that immediately gives up if another function is already running
 */
export class SingleInstanceRunner implements Runner {
	#running = false

	/**
	 * If the runner is currently running a function
	 */
	get running(): boolean {
		return this.#running
	}

	async run<T>(fn: () => Promise<T>): Promise<Awaited<T>> {
		if (this.#running) throw new Error('Already running')
		this.#running = true
		return await fn().finally(() => (this.#running = false))
	}

	/**
	 * Wrap a function to make sure only one instance is running at a time
	 */
	wrap<A extends any[], R>(
		fn: (...args: A) => Promise<R>
	): (...args: A) => Promise<Awaited<R>> {
		return (...args) => this.run(() => fn(...args))
	}

	/**
	 * Wrap a function to make sure only one instance is running at a time
	 */
	static wrap<A extends any[], R>(
		fn: (...args: A) => Promise<R>
	): (...args: A) => Promise<Awaited<R>> {
		return new SingleInstanceRunner().wrap(fn)
	}
}

/**
 * Iterate over numbers between start (inclusive) and end (exclusive), with a step of step
 *
 * @see {@link https://docs.python.org/3/library/itertools.html#itertools.count | `itertools.count` in python}
 */
export function* count(
	/**
	 * The beginning of the range, inclusive
	 *
	 * @defaultValue 0
	 */
	start = 0,
	/**
	 * The step between two numbers
	 *
	 * @defaultValue 1
	 */
	step = 1,
	/**
	 * The end of the range, exclusive
	 *
	 * If step is positive, this should be greater than start, otherwise lesser than start
	 *
	 * @defaultValue +/- Infinity
	 */
	end = step > 0 ? Infinity : -Infinity
): Iterable<number> {
	for (let i = start; step > 0 ? i < end : i > end; i += step) yield i
}

/**
 * Repeat a given element a given amount of times or infinitely
 *
 * @see {@link https://docs.python.org/3/library/itertools.html#itertools.repeat | `itertools.repeat` in python}
 */
export function* repeat<T>(
	/**
	 * The element to repeat
	 */
	e: T,
	/**
	 * How many times to repeat the element
	 *
	 * @defaultValue Infinity
	 */
	times?: number
): Iterable<T> {
	if (times != null) {
		for (let i = 0; i < times; i++) yield e
	} else {
		for (;;) yield e
	}
}

/**
 * Iterate over the entries of a plain object
 *
 * @see {@link https://www.lua.org/manual/5.4/manual.html#pdf-pairs | `pairs()` in lua}
 */
export function pairs<K extends string, V>(
	/**
	 * The plain object to iterate over
	 */
	obj: Record<K, V>
): Iterable<[K, V]> {
	return Object.entries(obj) as [K, V][]
}

/**
 * Iterate over the index and value of an array
 *
 * @see {@link https://www.lua.org/manual/5.4/manual.html#pdf-ipairs | `ipairs()` in lua}
 */
export function* ipairs<T>(
	/**
	 * The array to iterate over
	 */
	arr: T[]
): Iterable<[number, T]> {
	const len = arr.length
	for (let i = 0; i < len; i++) {
		yield [i, arr[i]]
	}
}

/**
 * Iterate over an iterator
 */
export function* iter<T>(
	/**
	 * The iterator to iterate over
	 */
	it: Iterator<T>
): Iterable<T> {
	while (true) {
		const e = it.next()
		if (e.done) return
		yield e.value
	}
}

/**
 * Iterate over another iterable, separating every value with a given separator
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.intersperse | `Iter.intersperse` in rust}
 */
export function* separate<T>(
	/**
	 * The iterable to iterate over
	 */
	it: Iterable<T>,
	/**
	 * The separator to injet between elements
	 */
	separator: T
): Iterable<T> {
	let first = true
	for (const x of it) {
		if (!first) yield separator
		yield x
		first = false
	}
}

/**
 * Iterate over every iterable in the list, retuning a value from the first, then the second, etc. then over again
 *
 * Finishes when any iterable finishes
 *
 * @see {@link https://more-itertools.readthedocs.io/en/stable/api.html#more_itertools.interleave | `more_itertools.interleave` in python}
 */
export function* intersperse<T>(
	/**
	 * The iterables to intersperse
	 */
	...iters: Iterable<T>[]
): Iterable<T> {
	const it = iters.map(x => x[Symbol.iterator]())
	const e: IteratorResult<T, any>[] = Array(it.length)
	while (true) {
		for (let i = 0; i < it.length; i++) {
			e[i] = it[i].next()
			if (e[i].done) return
		}
		for (const x of e) yield x.value
	}
}

/**
 * Zip together multiple iterables, returning tuples with one value from each iterable
 *
 * Finishes when any iterable finishes
 *
 * This version zips a single iterable
 *
 * @see {@link https://docs.python.org/3.12/library/functions.html#zip | `zip()` in python}
 */
export function zip<T>(it: Iterable<T>): Iterable<[T]>
/**
 * Zip together multiple iterables, returning tuples with one value from each iterable
 *
 * Finishes when any iterable finishes
 *
 * This version zips a two iterables, with tuple typings
 *
 * @see {@link https://docs.python.org/3.12/library/functions.html#zip | `zip()` in python}
 */
export function zip<A, B>(a: Iterable<A>, b: Iterable<B>): Iterable<[A, B]>
/**
 * Zip together multiple iterables, returning tuples with one value from each iterable
 *
 * Finishes when any iterable finishes
 *
 * This version zips any number of iterables with identical typing
 *
 * @see {@link https://docs.python.org/3.12/library/functions.html#zip | `zip()` in python}
 */
export function zip<T>(...iters: Iterable<T>[]): Iterable<T[]>
/**
 * Zip together multiple iterables, returning tuples with one value from each iterable
 *
 * Finishes when any iterable finishes
 *
 * @see {@link https://docs.python.org/3.12/library/functions.html#zip | `zip()` in python}
 */
export function* zip<T>(...iters: Iterable<T>[]): Iterable<T[]> {
	const it = iters.map(x => x[Symbol.iterator]())

	const e: IteratorResult<T, any>[] = Array(it.length)
	while (true) {
		for (let i = 0; i < it.length; i++) {
			e[i] = it[i].next()
			if (e[i].done) return
		}
		yield e.map(x => x.value)
	}
}

/**
 * Iterates over an iterable, returning tuples with one value and its position in the iteration
 *
 * Be careful, the order is [value, position], unlike the python `enumerate()` function which returns `(postition, value)`
 *
 * @see {@link https://docs.python.org/3.12/library/functions.html#enumerate | `enumerate()` in python}
 */
export function* enumerate<T>(
	/**
	 * The iterable to iterate over
	 */
	iter: Iterable<T>
): Iterable<[T, number]> {
	let i = 0
	for (const o of iter) yield [o, i++]
}

/**
 * Returns a new iterable containing only the values of the given iterable that pass the predicate
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.filter | `Iter.filter` in rust}
 */
export function* filter<T>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The predicate to use
	 */
	pred: (val: T) => boolean
): Iterable<T> {
	for (const val of iter) {
		if (pred(val)) yield val
	}
}

/**
 * Returns a new iterable containing every value of the given iterable, until the predicate returns false, at which point it stops
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.take_while | `Iter.take_while` in rust}
 */
export function* takeWhile<T>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The predicate to use
	 */
	pred: (val: T) => boolean
): Iterable<T> {
	for (const val of iter) {
		if (!pred(val)) return
		yield val
	}
}

/**
 * Returns a new iterable containing every value of the given iterable, starting when the predicate returns false for the first time
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.skip_while | `Iter.skip_while` in rust}
 */
export function* skipWhile<T>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The predicate to use
	 */
	pred: (val: T) => boolean
): Iterable<T> {
	let active = false
	for (const val of iter) {
		if (active || pred(val)) {
			active = true
			yield val
		}
	}
}

/**
 * Returns a new iterable containing the mapped values of the given iterable, by the given transform
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.map | `Iter.map` in rust}
 */
export function* map<T, U>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The transform to use
	 */
	transform: (val: T) => U
): Iterable<U> {
	for (const val of iter) yield transform(val)
}

/**
 * Returns a new iterable containing the successive state values (except the initial one, unlike the python version) of the given reducer, applied to the given iterable
 *
 * @see {@link https://docs.python.org/3/library/itertools.html#itertools.accumulate | `itertools.accumulate` in python}
 * @see {@link reduce}
 */
export function* reduced<T, S>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The reducer to use
	 */
	reducer: (val: T, state: S) => S,
	/**
	 * Initial reducer state
	 */
	initial: S
): Iterable<S> {
	let state = initial
	for (const val of iter) {
		state = reducer(val, state)
		yield state
	}
}

/**
 * Returns a new iterable containing the mapped values (from a stateful map function) of the given iterable by the given map function
 */
export function* statefulMap<T, S, U>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The stateful map function
	 */
	reducer: (val: T, state: S) => [S, U],
	/**
	 * Initial state
	 */
	initial: S
): Iterable<U> {
	let state = initial
	for (const val of iter) {
		const current = reducer(val, state)
		state = current[0]
		yield current[1]
	}
}

/**
 * Returns a new iterable containing the values of each iterable, one iterable after the other
 *
 * Version with a vararg of iterables
 *
 * @see {@link chainIt}
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.chain | `Iter.chain` in rust}
 */
export function* chain<T>(...iters: Iterable<T>[]): Iterable<T> {
	for (const iter of iters) for (const val of iter) yield val
}
/**
 * Returns a new iterable containing the values of each iterable, one iterable after the other
 *
 * Version with an iterable of iterables
 *
 * @see {@link chain}
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.chain | `Iter.chain` in rust}
 */
export function* chainIt<T>(iters: Iterable<Iterable<T>>): Iterable<T> {
	for (const iter of iters) for (const val of iter) yield val
}

/**
 * Repeat an iterable forever
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.cycle | `Iter.cycle` in rust}
 */
export function* cycle<T>(iter: Iterable<T>): Iterable<T> {
	const tab: T[] = []
	for (const val of iter) {
		tab.push(val)
		yield val
	}
	if (!tab.length) return
	while (true) {
		for (const val of tab) yield val
	}
}

/**
 * Repeat an iterable a given amount of times
 *
 * @see {@link cycle}
 */
export function* cycleN<T>(iter: Iterable<T>, n: number): Iterable<T> {
	if (!n) return
	const tab: T[] = []
	for (const val of iter) {
		if (n !== 1) tab.push(val)
		yield val
	}
	n--
	while (n) {
		for (const val of tab) yield val
		n--
	}
}

/**
 * Return an iterable containing a specified amount of items (at maximum) from a source iterable
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.take | `Iter.take` in rust}
 */
export function* take<T>(iter: Iterable<T>, n: number): Iterable<T> {
	if (n <= 0) return
	let i = 0
	for (const val of iter) {
		yield val
		i++
		if (i >= n) return
	}
}

/**
 * Return an iterable containing all but a specified amount of items from a source iterable
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.skip | `Iter.skip` in rust}
 */
export function* skip<T>(iter: Iterable<T>, n: number): Iterable<T> {
	const it = iter[Symbol.iterator]()
	for (let i = 0; i < n; i++) {
		const val = it.next()
		if (val.done) return
	}
	while (true) {
		const val = it.next()
		if (val.done) return
		yield val.value
	}
}

/**
 * Return an iterable batching the items from the source iterable into blocks of n items
 *
 * The last block might be smaller if the total item count is not a multiple of n (unlike the rust implementation that exposes them differently, but like the python implementation)
 *
 * @see {@link https://docs.python.org/3/library/itertools.html#itertools.batched | `itertools.batched` in python}
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.array_chunks | `Iter.array_chunks` in rust}
 */
export function* batch<T>(iter: Iterable<T>, n: number): Iterable<T[]> {
	let batch: T[] = []
	let l = 0
	for (const e of iter) {
		batch.push(e)
		l++
		if (l === n) {
			yield batch
			batch = []
			l = 0
		}
	}
	if (l) yield batch
}

/**
 * Returns an iterable containing windows around each element of this iterable, with the given amount of pre- and post- elements
 *
 * @see {@link https://more-itertools.readthedocs.io/en/stable/api.html#more_itertools.windowed | `more_itertools.windowed` in python}
 */
export function* window<T>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * How many elements to return in the pre- part
	 */
	beforeCount: number,
	/**
	 * How many elements to return in the post- part
	 */
	afterCount: number
): Iterable<[T[], T, T[]]> {
	const before: T[] = []
	// dude trust me
	let current: T = void 0 as T
	const after: T[] = []
	let beforeLen = 0
	let afterLen = 0
	let hasCurrent = false

	for (const e of iter) {
		after.push(e)
		afterLen++
		if (afterLen > afterCount) {
			if (hasCurrent) {
				before.push(current)
				beforeLen++
			}
			if (beforeLen > beforeCount) {
				before.shift()
				beforeLen--
			}
			current = after.shift()!
			afterLen--
			hasCurrent = true
			yield [[...before], current, [...after]]
		}
	}
	while (afterLen) {
		if (hasCurrent) {
			before.push(current)
			beforeLen++
		}
		if (beforeLen > beforeCount) {
			before.shift()
			beforeLen--
		}
		current = after.shift()!
		afterLen--
		hasCurrent = true
		yield [[...before], current, [...after]]
	}
}

/**
 * Return an iterable containing the values of every iterable returned by calling the map on the provided iterable
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.flat_map | `Iter.flat_map` in rust}
 */
export function* flatMap<T, U>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>,
	/**
	 * The transform function, returning iterables
	 */
	transform: (val: T) => Iterable<U>
): Iterable<U> {
	for (const it of iter) {
		for (const val of transform(it)) {
			yield val
		}
	}
}

/**
 * Read the first n elements (at most) ofc the provided iterable, and return them along with an iterable that still contains them
 *
 * @see {@link peekable}
 * @see {@link https://more-itertools.readthedocs.io/en/stable/api.html#more_itertools.spy | `more_itertools.spy` in python}
 */
export function peek<T>(
	/**
	 * The iterable to peek at
	 */
	iterable: Iterable<T>,
	/**
	 * How many elements to peek
	 */
	n: number
): [T[], Iterable<T>] {
	if (n === 0) return [[], iterable]

	const it = iterable[Symbol.iterator]()
	const peeked: T[] = []

	for (let i = 0; i < n; i++) {
		const e = it.next()
		if (e.done) {
			return [peeked, peeked]
		}
		peeked.push(e.value)
	}

	return [peeked, chain(peeked, iter(it))]
}

/**
 * Returns an iterable that can be peeked (reading the top element without advancing), from a given iterable
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.peekable | `Iter.peekable` in rust}
 */
export function peekable<T>(
	/**
	 * The source iterable
	 */
	iter: Iterable<T>
): Iterable<T> & { peek: () => T; done: boolean } {
	// dude trust me
	let state: [T, false] | [null, true] = [void 0 as T, false]
	const it = iter[Symbol.iterator]()

	function advance() {
		if (state[1]) return
		const e = it.next()
		if (e.done) state = [null, true]
		else state = [e.value, false]
	}
	advance()

	return {
		[Symbol.iterator]() {
			return {
				next() {
					if (state[1]) return { done: true, value: void 0 }
					const value = state[0]
					advance()
					return { value }
				},
			}
		},
		get done() {
			return state[1]
		},
		peek() {
			if (state[1]) throw new Error('Iterable ended')
			return state[0]
		},
	}
}

/**
 * Reduce an iterable down to a single value, with the given reducer
 *
 * @see {@link https://doc.rust-lang.org/std/iter/trait.Iterator.html#method.fold | `Iter.fold` in rust}
 */
export function reduce<T, S>(
	/**
	 * The iterable to reduce
	 */
	iter: Iterable<T>,
	/**
	 * The reducer to use
	 */
	reducer: (val: T, state: S) => S,
	/**
	 * Initial state for the reducer
	 */
	initial: S
): S {
	let state = initial
	for (const val of iter) state = reducer(val, state)
	return state
}

/**
 * Find the first value in the iterable that matches the predicate and returns it
 *
 * If not found, returns the provided value
 *
 * @see {@link https://more-itertools.readthedocs.io/en/stable/api.html#more_itertools.first_true | `more_itertools.first_true` in python}
 */
export function find<T, X>(
	/**
	 * The iterable to find the value in
	 */
	iter: Iterable<T>,
	/**
	 * The predicate to find the value
	 */
	predicate: (val: T) => boolean,
	/**
	 * The value to return if the value is not found
	 */
	notFound: X
): T | X {
	for (const val of iter) if (predicate(val)) return val
	return notFound
}

/**
 * Iterates over the iterable and returns true (and stop iterating) if any value matches the predicate
 *
 * @see {@link every}
 */
export function some<T>(
	/**
	 * The iterable to find the value in
	 */
	iter: Iterable<T>,
	/**
	 * The predicate that should match
	 */
	predicate: (val: T) => boolean
): boolean {
	for (const val of iter) if (predicate(val)) return true
	return false
}

/**
 * Iterates over the iterable and returns false (and stop iterating) if any value doesn't match the predicate
 *
 * @see {@link every}
 */
export function every<T>(
	/**
	 * The iterable to find the value in
	 */
	iter: Iterable<T>,
	/**
	 * The predicate that should match
	 */
	predicate: (val: T) => boolean
): boolean {
	for (const val of iter) if (!predicate(val)) return false
	return true
}

/**
 * Iterate over the entire iterable and return the number of items
 *
 * @see {@link https://more-itertools.readthedocs.io/en/stable/api.html#more_itertools.ilen | `more_itertools.ilen` in python}
 */
export function length(
	/**
	 * The iterable to iterate over
	 */
	iter: Iterable<any>
): number {
	if (Array.isArray(iter)) return iter.length
	let c = 0
	for (const _ of iter) {
		void _
		c++
	}
	return c
}

/**
 * Iterate over the entire iterable and run a function for every item
 */
export function forEach<T>(
	/**
	 * The iterable to iterate over
	 */
	iter: Iterable<T>,
	/**
	 * The function to run on every item
	 */
	fn: (val: T) => void
): void {
	for (const val of iter) fn(val)
}

/**
 * Identity function, returns its argument unchanged
 */
export function id<T>(i: T) {
	return i
}

/**
 * Identity assertion, always passes
 */
export function idAssert<T>(i: any): asserts i is T {
	//
}

/**
 * Magic cast, returns its argument unchanged, but with a different type
 *
 * Equivalent to mem::transmute in rust, and has the same caveats
 */
export function magicCast<A, B>(val: B) {
	return val as unknown as A
}

/**
 * Helper type that can be used the same way as a {@link MapSet}
 *
 * @see {@link mapGetSet}
 * @see {@link mapAddSet}
 * @see {@link mapDelSet}
 * @see {@link mapSizeSet}
 */
export type AlmostMapSet<K, V> = Map<K, Set<V>>

/**
 * Get the set of values for a given key in a {@link AlmostMapSet}, creating it if it doesn't exist
 */
export function mapGetSet<K, V>(map: Map<K, Set<V>>, key: K): Set<V> {
	let set = map.get(key)
	if (!set) {
		set = new Set()
		map.set(key, set)
	}
	return set
}

/**
 * Add a value to the set for a given key, creating it if it doesnt exist, in a {@link AlmostMapSet}
 */
export function mapAddSet<K, V>(map: Map<K, Set<V>>, key: K, value: V) {
	const set = mapGetSet(map, key)
	set.add(value)
}

/**
 * Remove a value from the set for a given key in a {@link AlmostMapSet}
 */
export function mapDelSet<K, V>(
	map: Map<K, Set<V>>,
	key: K,
	value: V
): boolean {
	const set = map.get(key)
	if (!set) return false
	const found = set.delete(value)
	if (!set.size) map.delete(key)
	return found
}

/**
 * Get the size of the set for a given key in a {@link AlmostMapSet}
 */
export function mapSizeSet<K>(map: Map<K, Set<unknown>>, key: K): number {
	const set = map.get(key)
	if (!set) return 0
	return set.size
}

/**
 * Wrapper around {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map | Map}, with sets for each key
 *
 * @see {@link AlmostMapSet}
 */
export class MapSet<K, V> extends Map<K, Set<V>> {
	/**
	 * Get the value set for a given key, creating it if necessary
	 */
	getSet(key: K): Set<V> {
		return mapGetSet(this, key)
	}
	/**
	 * Add a value to the set for a given key, creating it if necessary
	 */
	addSet(key: K, value: V): void {
		return mapAddSet(this, key, value)
	}
	/**
	 * Delete a value from the set for a given key
	 */
	delSet(key: K, value: V): boolean {
		return mapDelSet(this, key, value)
	}
	/**
	 * Check the size of the set for a given key
	 */
	sizeSet(key: K): number {
		return mapSizeSet(this, key)
	}
}

/**
 * Get the array of values for a given key, creating it if it doesn't exist
 */
export function mapGetArr<K, V>(map: Map<K, V[]>, key: K): V[] {
	let arr = map.get(key)
	if (!arr) {
		arr = []
		map.set(key, arr)
	}
	return arr
}

/**
 * Add a value to the array for a given key, creating it if it doesnt exist
 */
export function mapAddArr<K, V>(map: Map<K, V[]>, key: K, value: V) {
	const arr = mapGetArr(map, key)
	arr.push(value)
}

/**
 * Remove a value from the array for a given key
 */
export function mapDelArr<K, V>(map: Map<K, V[]>, key: K, value: V): boolean {
	const arr = map.get(key)
	if (!arr) return false
	const idx = arr.indexOf(value)
	if (idx !== -1) {
		arr.splice(idx, 1)
		if (!arr.length) map.delete(key)
		return true
	}
	return false
}

/**
 * Get the size of the array for a given key
 */
export function mapSizeArr<K>(map: Map<K, unknown[]>, key: K): number {
	const set = map.get(key)
	if (!set) return 0
	return set.length
}

/**
 * Wrapper around {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map | Map}, with arrays for each key
 *
 * @see {@link MapSet}
 */
export class MapArr<K, V> extends Map<K, V[]> {
	/**
	 * Get the value array for a given key
	 */
	getArr(key: K): V[] {
		return mapGetArr(this, key)
	}
	/**
	 * Append to the value array at the given key
	 */
	addArr(key: K, value: V): void {
		return mapAddArr(this, key, value)
	}
	/**
	 * Delete from the value array at the given key
	 */
	delArr(key: K, value: V): boolean {
		return mapDelArr(this, key, value)
	}
	/**
	 * Get the size of the value array at the given key
	 */
	sizeArr(key: K): number {
		return mapSizeArr(this, key)
	}
}

import { reactHistory } from '@/hooks/useCancellableLocation'
import { pageInfos, type PageInfo } from '@/sitemap'

import { matchPaths } from './paths'

export function getCurrentBranch(): string | null {
	let pageInfo: PageInfo | undefined = undefined
	let params: Record<string, string> = {}

	if (reactHistory.current in pageInfos) {
		pageInfo = pageInfos[reactHistory.current]
	}

	if (!pageInfo) {
		const match = matchPaths(reactHistory.current, Object.keys(pageInfos))
		if (match) {
			pageInfo = pageInfos[match[0]]
			params = match[1]
		}
	}
	return params.currentBranch || null
}

import { escapeHtml } from './escapeHtml'

/**
 * Pad a value to get a certain length (def: 2) with something (def: '0') on the left
 */
export function zpad(
	/**
	 * Input value
	 */
	v: string | number,
	/**
	 * Minimum length
	 *
	 * @defaultValue 2
	 */
	n = 2,
	/**
	 * Padding value
	 *
	 * @defaultValue '0'
	 */
	p = '0'
): string {
	v = '' + v
	while (v.length < n) v = p + v
	return v
}

/**
 * Formats a date so that it's readable by a normal user
 * Format: `DD/MM/YYYY`
 */
export function fmtDate(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return `${zpad(date.getDate())}/${zpad(date.getMonth() + 1)}/${zpad(
		date.getFullYear()
	)}`
}

/**
 * Formats a time so that it's readable by a normal user
 * Format: `hh:mm:ss`
 */
export function fmtTime(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return `${zpad(date.getHours())}:${zpad(date.getMinutes())}:${zpad(
		date.getSeconds()
	)}`
}

/**
 * Formats a date in long format
 * Format: `<depends on locale>`
 */
export function fmtDateLocaleLong(
	date: string | Date,
	{
		locale = 'fr-FR',
		includeDay = true,
	}: { locale?: string; includeDay?: boolean } = {}
): string {
	if (!(date instanceof Date)) date = new Date(date)
	return date.toLocaleDateString(locale, {
		month: 'long',
		year: 'numeric',
		day: includeDay ? 'numeric' : undefined,
	})
}

/**
 * Formats a date and time so that it's readable by a normal user
 * Format: `DD/MM/YYYY hh:mm:ss`
 */
export function fmtDateTime(date: string | Date): string {
	return `${fmtDate(date)} ${fmtTime(date)}`
}

/**
 * Formats a date so that it's readable by a normal computer
 * Format: `YYYY-MM-DD`
 */
export function isoDate(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return `${zpad(date.getFullYear(), 4)}-${zpad(date.getMonth() + 1)}-${zpad(
		date.getDate()
	)}`
}

/**
 * Formats a date and time so that it's readable by a normal computer
 * Format: `YYYY-MM-DDTHH:MM:SS.mmmZ`
 */
export function isoDateTime(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return date.toISOString()
}

/**
 * Formats a time so that it's readable by a normal computer
 * Format: `HH:MM`
 */
export function isoTime(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return date.toISOString().split('T')[1].slice(0, 5)
}

/**
 * Formats a date in navitia format
 * Format: `YYYYMMDDThhmm00`
 */
export function navitiaDate(date: string | Date): string {
	if (!(date instanceof Date)) date = new Date(date)
	return `${zpad(date.getFullYear(), 4)}${zpad(date.getMonth() + 1)}${zpad(
		date.getDate()
	)}T${zpad(date.getHours())}${zpad(date.getMinutes())}00`
}

/**
 * Parses a date in navitia format
 */
export function parseNavitiaDate(navitia: string): Date {
	const match = navitia.match(
		/^(\d{4})(\d{2})(\d{2})(?:T(\d{2})(\d{2})(\d{2}))?$/
	)
	if (!match) throw new Error('Not a navitia date')
	const [, yyyy, mm, dd, HH, MM, SS] = match
	if (HH == null) return new Date(`${yyyy}-${mm}-${dd}`)
	return new Date(`${yyyy}-${mm}-${dd}T${HH}:${MM}:${SS}`)
}

/**
 * Formats a duration so that it's readable by a normal user
 */
export function fmtDuration(ms: number): string {
	let suffix = 'ms'
	let duration = ms
	for (const [x, s] of [
		[1000, 's'],
		[60, 'm'],
		[60, 'h'],
		[24, 'd'],
		[7, 'w'],
	] as [number, string][]) {
		if (duration > x) {
			duration /= x
			suffix = s
		} else {
			break
		}
	}
	return `${Math.round(100 * duration) / 100}${suffix}`
}

/**
 * Formats a size, given in bytes
 * Returns something like nSo where 1\<=n\<=1024, n has max 2 decimal digits and S is a suffix up to T
 */
export function fmtSize(size: number): string {
	let suffix = ''
	;['k', 'M', 'G', 'T'].forEach(s => {
		if (size > 1024) {
			size /= 1024
			suffix = s
		}
	})
	return `${Math.round(size * 100) / 100}${suffix}o`
}

/**
 * Formats a size, given in bytes
 * Returns something like n So where 1\<=n\<=1024, n has 2 decimal digits and S is a suffix up to T
 */
export function fmtSize2(size: number): string {
	let suffix = ''
	;['k', 'M', 'G', 'T'].forEach(s => {
		if (size > 1024) {
			size /= 1024
			suffix = s
		}
	})
	return `${size.toFixed(2)} ${suffix}o`
}

/**
 * Formats a phone number
 * Groups digits by pairs of 2
 */
export function fmtTel(tel: string): string {
	tel = tel.replace(/[. -]/g, '')
	const prefix = tel[0] === '+'
	if (prefix) tel = tel.substring(1)
	const telArr = Array.from(tel)
	for (let i = telArr.length - 2; i > 1; i -= 2) telArr.splice(i, 0, ' ')
	if (prefix) telArr.unshift('+')
	return telArr.join('')
}

/**
 * Transformations that can be applied to a text's case
 */
export type CaseMode =
	/**
	 * lowercase (`toLocaleLowerCase`)
	 */
	| 'lower'
	/**
	 * UPPERCASE (`toLocaleUpperCase`)
	 */
	| 'upper'
	/**
	 * raw lowercase (`toLowerCase`)
	 */
	| 'rawLower'
	/**
	 * RAW UPPERCASE (`toUpperCase`)
	 */
	| 'rawUpper'
	/**
	 * Title Case (first letter of each word)
	 */
	| 'title'
	/**
	 * Capitalize (first letter of the text)
	 */
	| 'capitalize'

/**
 * Apply case formatting to a text (see {@link CaseMode})
 */
export function fmtCase(text: string, caseMode: CaseMode): string {
	switch (caseMode) {
		case 'lower':
			return text.toLocaleLowerCase()
		case 'upper':
			return text.toLocaleUpperCase()
		case 'rawLower':
			return text.toLowerCase()
		case 'rawUpper':
			return text.toUpperCase()
		case 'title':
			return text.replace(/\p{Letter}+/gu, x => fmtCase(x, 'capitalize'))
		case 'capitalize':
			return text
				.toLocaleLowerCase()
				.replace(/^\p{White_Space}*\p{Letter}/u, x =>
					x.toLocaleUpperCase()
				)
	}
}

/**
 * Format modes for generic data
 */
export type FmtMode =
	/**
	 * Only touch the capitalization of the text
	 */
	| CaseMode
	/**
	 * Format as a date ({@link fmtDate})
	 */
	| 'date'
	/**
	 * Format as a time ({@link fmtTime})
	 */
	| 'time'
	/**
	 * Format as a datetime ({@link fmtDateTime})
	 */
	| 'datetime'
	/**
	 * Format as an ISO date ({@link isoDate})
	 */
	| 'isodate'
	/**
	 * Format as a Navitia¨date ({@link navitiaDate})
	 */
	| 'navitiadate'
	/**
	 * Format as a duration ({@link fmtDuration})
	 */
	| 'duration'
	/**
	 * Format as a (file) size ({@link fmtSize})
	 */
	| 'size'
	/**
	 * Format as a (file) size ({@link fmtSize2})
	 */
	| 'size2'
	/**
	 * Format as a phone number ({@link fmtTel})
	 */
	| 'tel'
	/**
	 * Escape HTML
	 */
	| 'html'
	/**
	 * Escape URL/URI component
	 */
	| 'url'
	/**
	 * Do nothing, just stringify
	 */
	| ''

/**
 * Types that can be formatted through {@link fmt}
 */
export type Formattable = string | number | Date

/**
 * Format {@link Formattable} data with a {@link FmtMode}
 */
export function fmt(fmtMode: FmtMode | null, input: Formattable): string {
	switch (fmtMode ?? '') {
		case 'lower':
		case 'upper':
		case 'rawLower':
		case 'rawUpper':
		case 'title':
		case 'capitalize':
			return fmtCase('' + input, fmtMode as CaseMode)

		case 'date':
			return fmtDate(new Date(input))
		case 'time':
			return fmtTime(new Date(input))
		case 'datetime':
			return fmtDateTime(new Date(input))
		case 'isodate':
			return isoDate(new Date(input))
		case 'navitiadate':
			return navitiaDate(new Date(input))

		case 'duration':
			return fmtDuration(+('' + input))
		case 'size':
			return fmtSize(+('' + input))
		case 'size2':
			return fmtSize2(+('' + input))

		case 'tel':
			return fmtTel('' + input)

		case 'html':
			return escapeHtml('' + input)
		case 'url':
			return encodeURIComponent('' + input)

		case '':
			return '' + input

		default:
			console.warn(
				`fmt('${fmtMode}', ${
					input instanceof Date
						? isoDate(input)
						: JSON.stringify(input)
				}): invalid or unknown mode`
			)
			return '' + input
	}
}

import delayedPromise from './delayedPromise'
import type { Runner } from './runner'

/**
 * What to do when an {@link Executor} runs into an error with a task
 */
export type ErrorBehavior = 'abort' | 'skip'

/**
 * Task object for an {@link Executor}
 */
export type Task = [
	/**
	 * Function to run as the task
	 */
	fn: () => Promise<any>,
	/**
	 * What to do if the task fails
	 */
	errorBehavior: ErrorBehavior,
	/**
	 * Task ticket, used to track its status
	 */
	ticket: number,
]

/**
 * Parallel executor, able to run multiple tasks at once with a limit on concurrency, events and custom behavior
 */
class Executor implements Runner {
	#concurrencyLimit: number
	#running: Set<Promise<any>> = new Set()
	#pending: Task[] = []
	#errorBehavior: ErrorBehavior
	#onFail: Set<() => void> = new Set()
	#onError: Set<(err: any) => void> = new Set()
	#onResult: Set<(ticket: number) => void> = new Set()
	#onRun: Set<(...args: Task) => void> = new Set()
	#onSchedule: Set<(...args: Task) => void> = new Set()
	#onCancel: Set<(...args: Task) => void> = new Set()
	#failed: null | Error = null
	#lastTicket = 0

	/**
	 * Build a new executor
	 */
	constructor(config?: {
		/**
		 * How many tasks to run in parallel at maximum
		 *
		 * Setting this to 0 allows running infinite tasks in parallel
		 * @defaultValue 1
		 */
		concurrencyLimit?: number
		/**
		 * What to do if a task fails
		 * @defaultValue 'skip'
		 */
		errorBehavior?: ErrorBehavior
		/**
		 * Event handler when the executor fails
		 */
		onFail?: () => void
		/**
		 * Event handler when a task fails
		 */
		onError?: (err: any) => void
		/**
		 * Event handler when a task completes
		 */
		onResult?: () => void
		/**
		 * Event handler when a task starts running
		 */
		onRun?: (...args: Task) => void
		/**
		 * Event handler when a task gets scheduled
		 */
		onSchedule?: (...args: Task) => void
		/**
		 * Event handler when a task gets cancelled
		 */
		onCancel?: (...args: Task) => void
	}) {
		if (config?.concurrencyLimit)
			this.#concurrencyLimit = config.concurrencyLimit
		else this.#concurrencyLimit = 1

		if (config?.errorBehavior) this.#errorBehavior = config.errorBehavior
		else this.#errorBehavior = 'skip'

		if (config?.onFail) this.#onFail.add(config.onFail)
		if (config?.onError) this.#onError.add(config.onError)
		if (config?.onResult) this.#onResult.add(config.onResult)
		if (config?.onRun) this.#onRun.add(config.onRun)
		if (config?.onSchedule) this.#onSchedule.add(config.onSchedule)
		if (config?.onCancel) this.#onCancel.add(config.onCancel)
	}

	/**
	 * How many tasks can run at once
	 */
	get concurrencyLimit(): number {
		return this.#concurrencyLimit
	}
	/**
	 * How many tasks are currently running
	 */
	get runningCount(): number {
		return this.#running.size
	}
	/**
	 * How many tasks are currently pending
	 */
	get pendingCount(): number {
		return this.#pending.length
	}
	/**
	 * How many tasks could be scheduled more before reaching the limit
	 */
	get slots(): number {
		if (this.#concurrencyLimit === 0) return Infinity
		if (this.pendingCount) return 0
		const slots = this.#concurrencyLimit - this.runningCount
		if (slots < 0) return 0
		return slots
	}
	/**
	 * Can a new task be scheduled and run immediately
	 */
	get free(): boolean {
		if (this.#concurrencyLimit === 0) return true
		if (this.runningCount >= this.#concurrencyLimit) return false
		if (this.pendingCount) return false
		return true
	}
	/**
	 * What does the executor do if a task fails
	 */
	get errorBehavior(): ErrorBehavior {
		return this.#errorBehavior
	}
	/**
	 * The error with which the executor failed, if it did fail
	 */
	get failed(): null | Error {
		return this.#failed
	}

	/**
	 * Throws the error that failed the executor, if it did fail
	 */
	assertNotFailed(): void {
		if (this.#failed) throw this.#failed
	}

	/**
	 * Registers a new handler for task errors
	 */
	onError(handler: (err: any) => void): void {
		this.assertNotFailed()
		this.#onError.add(handler)
	}
	/**
	 * Unregisters a handler for task errors
	 */
	offError(handler: (err: any) => void): void {
		this.#onError.delete(handler)
	}
	#dispatchError(err: any): void {
		this.#onError.forEach(handler => {
			try {
				handler(err)
			} catch (e) {
				console.error(e)
			}
		})
	}

	/**
	 * Registers a new handler for task results
	 */
	onResult(handler: (ticket: number) => void): void {
		this.assertNotFailed()
		this.#onResult.add(handler)
	}
	/**
	 * Unregisters a handler for task results
	 */
	offResult(handler: (ticket: number) => void): void {
		this.#onResult.delete(handler)
	}
	#dispatchResult(ticket: number): void {
		this.#onResult.forEach(handler => {
			try {
				handler(ticket)
			} catch (e) {
				console.error(e)
			}
		})
	}
	/**
	 * Waits until the next task either completes or fails
	 */
	async waitResult(): Promise<void> {
		this.assertNotFailed()

		let okHandler: (() => void) | null = null
		let errHandler: ((err: any) => void) | null = null
		try {
			await new Promise<void>((ok, ko) => {
				if (this.#errorBehavior === 'abort') {
					errHandler = ko
					this.onError(ko)
				} else {
					errHandler = ok
					this.onError(ok)
				}
				okHandler = ok
				this.onResult(okHandler)
			})
		} finally {
			if (errHandler) this.offError(errHandler)
			if (okHandler) this.offResult(okHandler)
		}

		this.assertNotFailed()
	}

	/**
	 * Registers a new handler for task startup
	 */
	onRun(handler: (...args: Task) => void): void {
		this.assertNotFailed()
		this.#onRun.add(handler)
	}
	/**
	 * Unregisters a handler for task startup
	 */
	offRun(handler: (...args: Task) => void): void {
		this.#onRun.delete(handler)
	}
	#dispatchRun(task: Task): void {
		this.#onRun.forEach(handler => {
			try {
				handler(...task)
			} catch (e) {
				console.error(e)
			}
		})
	}

	/**
	 * Registers a new handler for task scheduling
	 */
	onSchedule(handler: (...args: Task) => void): void {
		this.assertNotFailed()
		this.#onSchedule.add(handler)
	}
	/**
	 * Unregisters a handler for task scheduling
	 */
	offSchedule(handler: (...args: Task) => void): void {
		this.#onSchedule.delete(handler)
	}
	#dispatchSchedule(task: Task): void {
		this.#onSchedule.forEach(handler => {
			try {
				handler(...task)
			} catch (e) {
				console.error(e)
			}
		})
	}

	/**
	 * Registers a new handler for task cancellation
	 */
	onCancel(handler: (...args: Task) => void): void {
		this.assertNotFailed()
		this.#onCancel.add(handler)
	}
	/**
	 * Unregisters a handler for task cancellation
	 */
	offCancel(handler: (...args: Task) => void): void {
		this.#onCancel.delete(handler)
	}
	#dispatchCancel(task: Task): void {
		this.#onCancel.forEach(handler => {
			try {
				handler(...task)
			} catch (e) {
				console.error(e)
			}
		})
	}

	/**
	 * Registers a new handler for executor failure
	 */
	onFail(handler: () => void): void {
		if (this.#failed) return handler()
		this.#onFail.add(handler)
	}
	/**
	 * Unregisters a handler for executor failure
	 */
	offFail(handler: () => void): void {
		this.#onFail.delete(handler)
	}
	#dispatchFail(): void {
		this.#onFail.forEach(handler => {
			try {
				handler()
			} catch (e) {
				console.error(e)
			}
		})
	}

	/**
	 * True if the executor is done running all tasks, and is not failed
	 */
	get done(): boolean {
		return (
			this.runningCount === 0 && this.pendingCount === 0 && !this.failed
		)
	}
	/**
	 * Waits until the executor is {@link done} running all tasks, and throws if one fails
	 */
	async waitDone(): Promise<void> {
		while (!this.done) {
			await this.waitResult()
		}
	}

	#setFailed(e: unknown): void {
		this.#failed = e instanceof Error ? e : new Error('Executor failed')
	}

	#fail(e: unknown): void {
		this.#setFailed(e)
		this.#pending = []
		this.#dispatchFail()
		this.#onError.clear()
		this.#onResult.clear()
		this.#onRun.clear()
		this.#onFail.clear()
		this.#onSchedule.clear()
		this.#onCancel.clear()
	}

	#ticket(): number {
		return this.#lastTicket++
	}

	/**
	 * Schedule a task for execution, and returns its position in queue and and ticket
	 */
	schedule(
		/**
		 * Task to schedule
		 */
		fn: () => Promise<any>,
		{
			errorBehavior,
			priority,
		}: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
		} = {}
	): {
		/**
		 * Position in queue
		 */
		pos: number
		/**
		 * Task ticket
		 */
		ticket: number
	} {
		this.assertNotFailed()
		errorBehavior ??= this.#errorBehavior
		const ticket = this.#ticket()
		const task = [fn, errorBehavior, ticket] as Task
		const pos = priority
			? (this.#pending.unshift(task), 0)
			: this.#pending.push(task)
		this.#dispatchSchedule(task)
		setTimeout(() => this.#runNext())
		return { pos, ticket }
	}

	/**
	 * Cancel a pending task, given its ticket, and returns if it was successfully cancelled
	 *
	 * This can easily fail if the task is already running, or if it has already finished
	 *
	 * This should only be used as a slight performance boost if a task is no longer needed, as this does not guarantee that the task will not run
	 */
	cancel(ticket: number): boolean {
		const idx = this.#pending.findIndex(x => x[2] === ticket)
		if (idx === -1) return false
		const [task] = this.#pending.splice(idx, 1)
		this.#dispatchCancel(task)
		return true
	}

	/**
	 * Schedule a task, with handlers for its return promise
	 *
	 * @see {@link schedule | this.schedule}
	 */
	scheduleAndThen<T>(
		/**
		 * Task to schedule
		 */
		fn: () => Promise<T>,
		{
			errorBehavior,
			priority,
			then: onThen,
			catch: onCatch,
			finally: onFinally,
		}: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
			/**
			 * Then handler for the task's promise
			 */
			then?: (v: T) => void
			/**
			 * Catch handler for the task's promise
			 */
			catch?: (e: any) => void
			/**
			 * Finally handler for the task's promise
			 */
			finally?: () => void
		}
	): { pos: number; ticket: number } {
		return this.schedule(
			() => {
				const promise = fn()
				if (onThen) promise.then(onThen).catch(e => void e)
				if (onCatch) promise.catch(onCatch)
				else promise.catch(e => void e)
				if (onFinally) promise.finally(onFinally)
				return promise
			},
			{ errorBehavior, priority }
		)
	}

	/**
	 * Schedule a task to be executed, and waits for it to complete, returning its result or throwing its error
	 *
	 * @see {@link schedule | this.schedule}
	 */
	async scheduleAndWait<T>(
		fn: () => Promise<T>,
		{
			errorBehavior,
			priority,
		}: { errorBehavior?: ErrorBehavior; priority?: boolean } = {}
	): Promise<T> {
		return new Promise<T>((ok, ko) => {
			this.scheduleAndThen(fn, {
				then: ok,
				catch: ko,
				errorBehavior,
				priority,
			})
		})
	}

	/**
	 * Alias to {@link scheduleAndWait} without options, for the {@link Runner} interface
	 */
	run<T>(fn: () => Promise<T>): Promise<Awaited<T>> {
		return this.scheduleAndWait(fn).then(async x => await x) as Promise<
			Awaited<T>
		>
	}

	/**
	 * Schedule a list of tasks to be executed, and wait for them all to complete (or one to fail, depending on parameters)
	 *
	 * This version throws if any of the tasks fails
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all | Promise.all}
	 */
	async scheduleAndWaitAll<T extends readonly unknown[] | []>(
		/**
		 * Tuple of tasks to be executed
		 */
		fns: { [P in keyof T]: () => Promise<T[P]> },
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
			/**
			 * If false, throws when any task fails; if true, each result can be either success or failure
			 */
			individualFail?: false
		}
	): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }>
	/**
	 * Schedule a list of tasks to be executed, and wait for them all to complete (or one to fail, depending on parameters)
	 *
	 * This version returns a status for each task
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all | Promise.all}
	 */
	async scheduleAndWaitAll<T extends readonly unknown[] | []>(
		/**
		 * Tuple of tasks to be executed
		 */
		fns: { [P in keyof T]: () => Promise<T[P]> },
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: 'skip'
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
			/**
			 * If false, throws when any task fails; if true, each result can be either success or failure
			 */
			individualFail: true
		}
	): Promise<{
		-readonly [P in keyof T]:
			| { status: 'ok'; value: Awaited<T[P]> }
			| { status: 'ko'; error: any }
	}>
	/**
	 * Schedule a list of tasks to be executed, and wait for them all to complete (or one to fail, depending on parameters)
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all | Promise.all}
	 */
	async scheduleAndWaitAll<T>(
		/**
		 * Tuple of tasks to be executed
		 */
		fns: (() => Promise<T>)[],
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
			/**
			 * If false, throws when any task fails; if true, each result can be either success or failure
			 */
			individualFail?: boolean
		}
	): Promise<any[]> {
		const { errorBehavior, individualFail = false, priority } = params ?? {}

		const results = Array<any>(fns.length)
		let remaining = fns.length
		const deferred = delayedPromise<T[]>()
		if (!fns.length) setTimeout(() => deferred.resolve(results))

		fns.forEach((fn, i) => {
			this.scheduleAndThen(fn, {
				errorBehavior: individualFail ? 'skip' : errorBehavior,
				priority,
				then: v => {
					results[i] = individualFail ? { status: 'ok', value: v } : v
					remaining--
					if (!remaining) deferred.resolve(results)
				},
				catch: e => {
					if (individualFail) {
						results[i] = { status: 'ko', error: e }
						remaining--
						if (!remaining) deferred.resolve(results)
					} else {
						deferred.reject(e)
					}
				},
			})
		})

		return deferred
	}

	/**
	 * Schedule a list of tasks to be scheduled one after the other
	 *
	 * If any task fails, the remaining tasks will be skipped
	 *
	 * @see {@link schedule | this.schedule}
	 */
	scheduleSequential(
		/**
		 * Tasks to schedule
		 */
		fns: (() => Promise<void>)[],
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
			/**
			 * Then handler when all tasks are done running
			 */
			then?: () => void
			/**
			 * Catch handler when any task fails
			 */
			catch?: (e: any) => void
			/**
			 * Finally handler when either all tasks are done running, or one failed
			 */
			finally?: () => void
		}
	): void {
		const {
			errorBehavior,
			then: onThen,
			catch: onCatch,
			finally: onFinally,
		} = params ?? {}
		let { priority } = params ?? {}
		fns = [...fns]
		const scheduleNext = () => {
			const fn = fns.shift()
			if (!fn) {
				if (onThen) setTimeout(onThen)
				if (onFinally) setTimeout(onFinally)
				return
			}
			this.scheduleAndThen(fn, {
				errorBehavior,
				priority,
				then: scheduleNext,
				catch: e => {
					onCatch?.(e)
					onFinally?.()
				},
			})
			priority = true
		}
		scheduleNext()
	}

	/**
	 * Schedule a list of tasks to be scheduled sequentially, and wait for their results
	 *
	 * This is functionally equivalent to using {@link scheduleAndWait} in a loop (which is what it does)
	 *
	 * This version is the base case when there is no task to schedule
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link scheduleAndWait | this.scheduleAndWait}
	 */
	async scheduleSequentialAndWait(
		/**
		 * List of tasks to be executed (empty)
		 */
		fns: [],
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
		}
	): Promise<[]>
	/**
	 * Schedule a list of tasks to be scheduled sequentially, and wait for their results
	 *
	 * This is functionally equivalent to using {@link scheduleAndWait} in a loop (which is what it does)
	 *
	 * This version is the base with a tuple of tasks, for proper return types
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link scheduleAndWait | this.scheduleAndWait}
	 */
	async scheduleSequentialAndWait<T extends readonly unknown[]>(
		/**
		 * Tuple of tasks to be executed
		 */
		fns: { [P in keyof T]: () => Promise<T[P]> },
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
		}
	): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }>
	/**
	 * Schedule a list of tasks to be scheduled sequentially, and wait for their results
	 *
	 * This is functionally equivalent to using {@link scheduleAndWait} in a loop (which is what it does)
	 *
	 * This version is the case with a homogenous list of tasks with the same return type
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link scheduleAndWait | this.scheduleAndWait}
	 */
	async scheduleSequentialAndWait<T>(
		/**
		 * List of tasks to be executed
		 */
		fns: (() => Promise<T>)[],
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
		}
	): Promise<T[]>
	/**
	 * Schedule a list of tasks to be scheduled sequentially, and wait for their results
	 *
	 * This is functionally equivalent to using {@link scheduleAndWait} in a loop (which is what it does)
	 *
	 * @see {@link schedule | this.schedule}
	 * @see {@link scheduleAndWait | this.scheduleAndWait}
	 */
	async scheduleSequentialAndWait<T>(
		/**
		 * Tuple of tasks to be executed
		 */
		fns: (() => Promise<T>)[],
		params?: {
			/**
			 * What to do if the task fails
			 */
			errorBehavior?: ErrorBehavior
			/**
			 * Should this task take priority over currently pending tasks
			 */
			priority?: boolean
		}
	): Promise<T[]> {
		const results: T[] = []
		for (const fn of fns) {
			results.push(await this.scheduleAndWait(fn, params))
		}
		return results
	}

	async #runNext(): Promise<void> {
		if (this.failed) return
		if (
			this.concurrencyLimit &&
			this.runningCount >= this.concurrencyLimit
		) {
			return
		}

		const item = this.#pending.shift()
		if (!item) return

		this.#dispatchRun(item)
		const [fn, errorBehavior] = item
		let promise: Promise<void>
		try {
			promise = fn()
			promise.catch(e => void e)
		} catch (e) {
			if (errorBehavior === 'abort') this.#setFailed(e)
			this.#dispatchError(e)
			if (errorBehavior === 'abort') this.#fail(e)
			if (!this.failed) setTimeout(() => this.#runNext())
			return
		}
		this.#running.add(promise)

		try {
			await promise
			this.#running.delete(promise)
			this.#dispatchResult(item[2])
		} catch (e) {
			console.warn('Caught', e)
			this.#running.delete(promise)
			if (errorBehavior === 'abort') this.#setFailed(e)
			this.#dispatchError(e)
			if (errorBehavior === 'abort') this.#fail(e)
		} finally {
			if (!this.failed) setTimeout(() => this.#runNext())
		}
	}
}
export default Executor
